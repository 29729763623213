<template>
	<div class="bg-gray-100">
		<div class="max-w-7xl mx-auto py-12 sm:px-6 lg:px-8">
			<div class="max-w-3xl mx-auto">
				<div class="bg-white shadow sm:rounded-lg">
					<div class="px-4 py-5 sm:p-6">
						<h3 class="text-lg leading-6 font-medium text-gray-900">
							There are no Channels at the moment, let's fix that
						</h3>
						<div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
							<p>
								A Channel is where your Entities will be consumed.
							</p>
						</div>
						<div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
							<p>
								For example an Article (
								<em>Entity</em>
								) could be displayed on your Corporate Website (
								<em>Channel</em>
								)
							</p>
						</div>
						<model-animation />
						<div class="mt-3 text-sm leading-5">
							<router-link
								:to="{ name: 'create-channel' }"
								class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
							>
								Create your first Channel →
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import modelAnimation from '@/components/shared/modelAnimation'
export default {
	name: 'EmptyChannels',
	components: {
		modelAnimation
	}
}
</script>
