<template>
	<section>
		<top-bar title="All Channels" :action="{ name: 'Create Channel', to: { name: 'create-channel' } }" />
		<div v-if="!isLoading">
			<empty v-if="!sorted.length" />
			<list v-else :channels="sorted" />
		</div>
	</section>
</template>

<script>
import List from './List'
import { mapGetters, mapState } from 'vuex'
import { GET_ALL } from '@/store/actions.type'
import topBar from '@/components/shared/topBar'
import Empty from './Empty'

export default {
	name: 'ManageChannels',
	components: {
		List,
		Empty,
		topBar
	},
	computed: {
		...mapGetters('channels', ['sorted']),
		...mapState('ui', ['isLoading'])
	},
	created() {
		this.fetch()
	},
	methods: {
		fetch() {
			this.$store.dispatch(`channels/${GET_ALL}`).then(() => {})
		}
	}
}
</script>
